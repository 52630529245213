<template>
  <div class="w-100">
    <b-spinner v-if="spinnerShow" class="spinner" />
    <div class="container w-100">
      <component :is="block" @spinner="spinnerShow = false" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      spinnerShow: true,
    };
  },
  components: {
    TtnBlock: () => ({
      component: import("./components/ttn"),
    }),
  },
  computed: {
    block() {
      return this.$route.params.type + "-block";
    },
  },
  watch: {
    "$route.params.type"() {
      this.spinnerShow = true;
    },
  }
};
</script>

<style scoped></style>
